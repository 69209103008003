import { ScrollArea } from "@/components/ui/scroll-area";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { FaHome } from "react-icons/fa";
import {
  Link,
  NavLink,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { FaList, FaRegFileLines } from "react-icons/fa6";
import { FaRegLightbulb } from "react-icons/fa";
import { IoGlassesOutline } from "react-icons/io5";
import { useEffect, useState } from "react";
import { Separator } from "@/components/ui/separator";
import { MdOutlineLogout } from "react-icons/md";
import { MdDashboard } from "react-icons/md";
import InitiumLogo from "assets/initium-logos/primary-horizontal-colored-black.svg";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { PopoverClose } from "@radix-ui/react-popover";

import { useAuthStore } from "stores/useAuthStore";
import { logout } from "api";

export default function Navbar() {
  const { projectId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const link = `/project/${projectId}`;
  const [sideNav, setSideNav] = useState("home");
  useEffect(() => {
    console.log(pathname);
  }, [pathname]);

  const handleLogout = () => {
    logout();
    useAuthStore.getState().logout();
    return navigate("/login");
  };

  return (
    <div className="flex flex-col">
      <Link to="/" className="w-fit pb-2">
        <img
          className="w-44 aspect-auto"
          src={InitiumLogo}
          alt="Initium Logo"
          onClick={() => {
            navigate("/");
          }}
        />
      </Link>
      <div className="flex h-full">
        <div className="w-10 h-full text-center flex flex-col items-center justify-between bg-slate-300 rounded-lg p-1">
          <div className="flex flex-col gap-y-4">
            <button
              className="data-[active=true]:bg-offblack data-[active=true]:text-white rounded-lg hover:bg-pastel-blue p-1"
              data-active={sideNav === "home"}
              onClick={() => setSideNav("home")}
            >
              <FaHome className="w-6 h-6" />
            </button>
            {projectId && (
              <>
                <button
                  className="data-[active=true]:bg-offblack data-[active=true]:text-white rounded-lg hover:bg-pastel-blue p-1"
                  data-active={sideNav === "project"}
                  onClick={() => setSideNav("project")}
                >
                  <MdDashboard className="w-6 h-6" />
                </button>
                <button
                  className="data-[active=true]:bg-offblack data-[active=true]:text-white rounded-lg hover:bg-pastel-blue p-1"
                  data-active={sideNav === "ideation"}
                  onClick={() => setSideNav("ideation")}
                >
                  <FaRegLightbulb className="w-6 h-6" />
                </button>
                <button
                  className="data-[active=true]:bg-offblack data-[active=true]:text-white rounded-lg hover:bg-pastel-blue p-1"
                  data-active={sideNav === "grant"}
                  onClick={() => setSideNav("grant")}
                >
                  <FaRegFileLines className="w-6 h-6" />
                </button>
                <button
                  className="data-[active=true]:bg-offblack data-[active=true]:text-white rounded-lg hover:bg-pastel-blue p-1"
                  data-active={sideNav === "admin"}
                  onClick={() => setSideNav("admin")}
                >
                  <FaList className="w-6 h-6" />
                </button>
                <button
                  className="data-[active=true]:bg-offblack data-[active=true]:text-white rounded-lg hover:bg-pastel-blue p-1"
                  data-active={sideNav === "review"}
                  onClick={() => setSideNav("review")}
                >
                  <IoGlassesOutline className="w-6 h-6" />
                </button>
              </>
            )}
          </div>
          <Popover>
            <PopoverTrigger className="rounded-lg hover:bg-pastel-blue p-1">
              <MdOutlineLogout className="w-6 h-6" />
            </PopoverTrigger>
            <PopoverContent className="text-base">
              <div className="pb-2">Are you sure you want to logout?</div>
              <div className="flex justify-between">
                <PopoverClose className="text-fuchsia hover:underline">
                  Cancel
                </PopoverClose>
                <button className="btn-fuchsia" onClick={handleLogout}>
                  Confirm
                </button>
              </div>
            </PopoverContent>
          </Popover>
        </div>
        <div className="w-36 h-full flex flex-col gap-y-1">
          {sideNav === "home" && (
            <>
              <NavLink className="nav-link m-1" to="/" end>
                Projects
              </NavLink>
              <NavLink
                className="nav-link m-1"
                to="/calls/guidelines-assistant"
                end
              >
                Guidelines Assistant
              </NavLink>
              <NavLink className="nav-link m-1" to={"/profile"} end>
                Profile
              </NavLink>
              <NavLink
                className="nav-link m-1"
                to={"/profile/bookmarked-calls"}
                end
              >
                Your Bookmarks
              </NavLink>
            </>
          )}
          {sideNav === "project" && (
            <>
              <NavLink className="nav-link m-1" to={link} end>
                Dashboard
              </NavLink>
            </>
          )}
          {sideNav === "ideation" && (
            <>
              <NavLink className="nav-link" to={link + "/ideation-space"} end>
                Research Idea
              </NavLink>
              <NavLink
                className="nav-link"
                to={link + "/ideation-space/background-material"}
                end
              >
                Background Material
              </NavLink>
              <NavLink
                className="nav-link"
                to={link + "/ideation-space/brainstorming"}
                end
              >
                Brainstorm Ideas
              </NavLink>
              <NavLink
                className="nav-link"
                to={link + "/ideation-space/brainstorm-session"}
              >
                Brainstorm Sessions
              </NavLink>
            </>
          )}
          {sideNav === "grant" && (
            <>
              <NavLink
                className="nav-link"
                to={link + "/grant-space/selected-call"}
                end
              >
                Selected Call
              </NavLink>
              <NavLink
                className="nav-link"
                to={link + "/grant-space/recommended-calls"}
                end
              >
                Recommended Calls
              </NavLink>
              <NavLink
                className="nav-link"
                to={link + "/grant-space/search-calls"}
                end
              >
                Search for Calls
              </NavLink>
              <NavLink
                className="nav-link"
                to={link + "/grant-space/bookmarks"}
                end
              >
                Bookmarks
              </NavLink>
            </>
          )}
          {sideNav === "admin" && (
            <>
              <NavLink className="nav-link" to={link + "/admin-space"} end>
                Action Items
              </NavLink>
              <NavLink
                className="nav-link"
                to={link + "/admin-space/document-checklist"}
                end
              >
                Document Checklist
              </NavLink>
            </>
          )}
          {sideNav === "review" && (
            <>
              <NavLink className="nav-link" to={link + "/review-space"}>
                Proposal Reviews
              </NavLink>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
